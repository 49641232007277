import React from 'react'
import Layout from '../components/layout'

const SustainabilityGHG = ({ location }) => {
  return (
    <Layout location={location}>
    <section class="hero-carbon w-full flex justify-center items-center flex-col relative py-6 xl:py-28 text-center">
      <div class="lg:w-5/6 xl:max-w-5xl px-3">
        <h2 class="font-bold text-purple-100 text-4xl lg:text-5xl xl:text-7xl md:max-w-xl lg:max-w-7xl py-5 brmobile">Responsible Today, <br/>Sustainable Tomorrow.</h2></div>
        <p class='m-0 p-0 w-5/6 lg:max-w-4xl text-white'>At Vantage Circle, we advocate for a more sustainable and eco-friendly future for everyone and are dedicated to lowering GHG emissions.</p>
        <div class="grid justify-center gap-5 my-5 py-5 w-9/12 lg:max-w-xl">
      </div>
    </section>
    <section class='w-full flex place-content-center py-10 xl:py-16 bg-purple-100'>
      <div class='w-5/6 lg:max-w-7xl mx-auto lg:grid grid-cols-2'>
          <div class='mx-auto lg:mx-0'>
            <h2 class='homepage-heading text-center lg:text-left mb-8'>Navigating a Greener Path: Our Annual Greenhouse Gas Emissions Report</h2>
            <p class='text-center lg:text-left'>Explore our comprehensive Greenhouse Gas (GHG) report, where we transparently detail our efforts, progress, and commitment to reducing emissions. Discover how we're making strides toward a more sustainable future, one carbon footprint at a time.</p>
            <div class='w-full lg:w-4/6'><a class="vc-ghost-btn-black lato mx-auto lg:mx-0" style={{ borderRadius: '50px', padding: '10px 0px' }} href="https://res.cloudinary.com/vantagecircle/image/upload/v1694607648/prod/QES_Report_2022_23.pdf" target="_blank">Download Report 2022-23</a></div>
          </div>
          <div class='flex place-content-center mt-16 xl:my-0'>
              <img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/GHG-Landing-1bookwebp.webp' width="350" />
          </div>
      </div>
    </section>
    </Layout >
  )
}

export default SustainabilityGHG